<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
  </v-app>
</template>

<script>
export default {
  name: 'LayoutDefault',

  components: {
    DashboardCoreAppBar: () => import('@/components/core/AppBar'),
    DashboardCoreDrawer: () => import('@/components/core/Drawer'),
    DashboardCoreView: () => import('@/components/core/View')
  },

  data: () => ({
    expandOnHover: false
  })
}
</script>
